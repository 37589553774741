<template>
  <a-modal
    :width="650"
    :title="model && model.menuId ? '修改菜单' : '添加菜单'"
    :visible="visible"
    :confirmLoading="loading"
    okText="确认"
    cancelText="取消"
    @ok="handleOk"
    @cancel="() => {
      $emit('cancel')
      form.resetFields()
    }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-form-item label="上级菜单" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
          <a-tree-select
            v-decorator="['parentId', { initialValue: 0 }]"
            show-search
            style="width: 100%"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            placeholder="请选择父集菜单"
            allow-clear
            :tree-data="treeData"
          >
          </a-tree-select>
        </a-form-item>
        <a-form-item label="菜单来源">
          <a-radio-group v-decorator="['menuFrom', { rules: [{ required: true, message: '菜单来源不能为空' }]}]" @change="getMenuFrom">
            <a-radio v-for="item in menuFromOptions" :key="item.value" :value="item.value">
              {{ item.label }}
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="菜单类型" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
          <a-radio-group v-decorator="['menuType', { initialValue: 1 }]" @change="getMenuType">
            <a-radio v-for="item in menuTypeOptions" :key="item.value" :value="item.value">
              {{ item.label }}
            </a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item v-if="menuType != 3" label="菜单图标" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
          <icon-selector
            :iconList="iconList"
            @change="selectedIcon" @select="selectedMenuIcon" ref="iconSelector" :type="menuFrom">
            <a-input
              v-decorator="['menuIcon']"
              onfocus="() => {
                $refs['iconSelector'].openPopover()
              }"
              :read-only="true"
              placeholder="点击选择图标"
              style="width: 100%;">
              <a-icon v-if="menuFrom === 1" slot="prefix" :type="form.getFieldValue('menuIcon')" />
                <img
                  v-if="menuFrom === 2 && form.getFieldValue('menuIcon')"
                  slot="prefix"
                  :src="iconSrc"
                  style="width: 17px; height: 17px;"/>
            </a-input>
          </icon-selector>
        </a-form-item>
        <a-form-item v-if="menuFrom === 1" label="组件路径" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
          <a-input v-decorator="['component']" />
        </a-form-item>
        <a-row>
          <a-col :span="12">
            <a-form-item :label="menuTypeName + '名称'" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <a-input v-decorator="['menuName',{ rules: [{ required: true, message: menuTypeName + '名称不能为空' }] }]" :placeholder="menuTypeName + '显示的名称'" />
            </a-form-item>
          </a-col>
          <a-col v-if="menuType != 3" :span="12">
            <a-form-item label="路由地址" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <a-input v-decorator="['routerPath']" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="权限名称" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <a-input v-decorator="['permsName', { rules: [{ required: true, message: '权限名称不能为空' }]}]" placeholder="配置权限时显示的名称" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="权限标识" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <a-input v-decorator="['perms', { rules: [{ required: true, message: '权限标识不能为空' }]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="显示排序" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <a-input-number
                v-decorator="['sort', { initialValue: 1, rules: [{ required: true, message: '显示排序不能为空' }] }]"
                :min="1"
                :max="10000"
                style="width: 100%;"/>
            </a-form-item>
          </a-col>
          <a-col v-if="menuType != 3" :span="12">
            <a-form-item label="菜单显示" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <a-radio-group v-decorator="['showStatus', { initialValue: 1 }]">
                <a-radio :value="1">
                  显示
                </a-radio>
                <a-radio :value="0">
                  隐藏
                </a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col v-if="menuType != 3" :span="12">
            <a-form-item label="状态" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <a-radio-group v-decorator="['status', { initialValue: 0 }]" >
                <a-radio :value="0">
                  正常
                </a-radio>
                <a-radio :value="1">
                  停用
                </a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col v-if="menuType != 3" :span="12">
            <a-form-item label="是否外链" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <a-radio-group v-decorator="['isFrame', { initialValue: 0 }]">
                <a-radio :value="1">
                  是
                </a-radio>
                <a-radio :value="0">
                  否
                </a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>

        </a-row>
        <a-form-item label="备注" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
          <a-textarea v-decorator="['description']" />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import IconSelector from '@/components/IconSelector'
// FontAwesome
// import FontAwesomeSelector from '@/components/FontAwesomeSelector'
import {menuAdd, menuEdit, menuIcon} from '@/api/menu'

// 表单字段
const fields = [
  'menuId', 'parentId', 'menuFrom', 'menuType', 'menuIcon',
  'menuName', 'sort', 'isFrame', 'routerPath', 'component', 'permsName',
  'perms', 'showStatus', 'status', 'description'
]

const formLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 3 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 13 }
  }
}

// 树形node节点
const treeNode = [{
  title: '根菜单',
  value: 0,
  key: 0
}]

export default {
  name: 'CreateForm',
  components: {
    IconSelector
    // FontAwesomeSelector
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    // loading: {
    //   type: Boolean,
    //   default: () => false
    // },
    model: {
      type: Object,
      default: () => null
    },
    parentList: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    visible (NData, OData) {
      if (NData) {
        const parentTree = JSON.parse(JSON.stringify(this.parentList))
        const treeField = (list) => {
          list = list.map(val => {
            val.title = val.name
            val.value = val.key
            if (val.children) {
              treeField(val.children)
            }
            return val
          })
        }
        treeField(parentTree)
        treeNode[0]['children'] = parentTree
        this.treeData = treeNode
      }
    }
  },
  data () {
    return {
      form: this.$form.createForm(this),
      menuTypeName: '目录',
      menuType: 1,
      menuFrom: null,
      formLayout,
      value: null,
      menuFromOptions: [
        { value: 1, label: 'PC管理端' },
        { value: 2, label: '小程序端' }
      ],
      menuTypeOptions: [
        { label: '目录', value: 1 },
        { label: '菜单', value: 2 },
        { label: '按钮', value: 3 }
      ],
      treeData: [],
      loading: false,
      iconSrc: '',
      iconList: []
    }
  },
  inject: ['queryParam', 'getMenuList'],
  created () {
    // 注册表单
    fields.forEach(v => this.form.getFieldDecorator(v))

    // 修改时
    this.$watch('model', (n) => {
      fields.forEach(v => this.form.getFieldDecorator(v))
      this.model && this.form.setFieldsValue(pick(this.model, fields))
      this.getMenuType()
      this.getMenuFrom('init')
      console.log(n, 'nnnnn')
      this.setMenuIcon()
    })
  },
  methods: {
    setMenuIcon() {
      menuIcon().then(res => {
        if (res.code === 200 && res.rows) {
          this.iconSrc = ''
          this.iconList = res.rows.map(val => {
            val.url = process.env.VUE_APP_API_FILE_URL + val.url
            if (val.menuIcon === this.form.getFieldValue('menuIcon')) {
              this.iconSrc = val.url
            }
            return val
          })
        }
      }).catch(e => {
      })
    },
    selectedIcon (menuIcon) {
      this.form.setFieldsValue({ menuIcon: menuIcon })
    },
    selectedMenuIcon(item) {
      this.form.setFieldsValue({ menuIcon: item.menuIcon })
      this.iconSrc = item.url
    },
    getMenuTypeName() {
      for (let item of this.menuTypeOptions) {
        if (this.menuType == item.value) {
          this.menuTypeName = item.label
          break
        }
      }
    },
    getMenuType() {
      this.$nextTick(() => {
        this.menuType = this.form.getFieldValue('menuType')
        this.getMenuTypeName()
      })
    },
    getMenuFrom(state) {
      this.$nextTick(() => {
        this.menuFrom = this.form.getFieldValue('menuFrom')
        if (state !== 'init') {
          this.form.setFieldsValue({ menuIcon: '' })
          this.iconSrc = ''
        }
      })
    },
    handleOk() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          // 提交数据
          const submitData = JSON.parse(JSON.stringify(values))
          // 是否修改
          const isEdit = !!this.model.menuId
          // 添加
          let submitPort = menuAdd
          if (isEdit) {
            // 修改
            submitPort = menuEdit
            submitData.menuId = this.model.menuId
          }
          submitPort(submitData).then(res => {
            if (res.code === 200) {
              this.$message.success(`${isEdit ? '修改' : '添加'}成功`)
              this.$emit('ok')
              this.form.resetFields()
              this.getMenuList()
            }
            this.loading = false
          }).catch(e => {
            this.loading = false
            console.error(e)
          })
        }
      })
    }
  }
}
</script>
